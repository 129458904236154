@import '../../../../scss/theme-bootstrap';

.gnav-dropdown-formatter {
  &.gnav-dropdown-formatter--columns {
    .gnav-links {
      &__section {
        margin: 0;
        padding: 0;
        max-height: 280px;
        display: block;
        columns: 1 auto;
        column-gap: 0;
        &--two-column {
          columns: 2 auto;
        }
        &--three-column {
          columns: 3 auto;
        }
        &--four-column {
          columns: 4 auto;
        }
        &__content {
          .gnav-links__title {
            margin-bottom: 18px;
          }
        }
        .gnav-links {
          &__link {
            flex: auto;
            margin: 0 0 16px;
            max-width: 130px;
            padding-#{$rdirection}: 19px;
            padding-#{$ldirection}: 0;
            .link {
              display: block;
              line-height: 1.25;
            }
            &-shop__all {
              color: $color-red;
            }
          }
        }
      }
    }
  }
}
